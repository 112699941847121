import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../styles/footer.scss";

const Footer = () => {
  let today = new Date().getFullYear();
  let socials = [
    {
      url: "https://www.facebook.com/helloario/",
      name: "facebook",
      icon: "../../../../icons/facebook-outline.svg",
    },
    {
      url: "https://twitter.com/helloario",
      name: "twitter",
      icon: "../../../../icons/twitter-outline.svg",
    },
    {
      url: "https://in.linkedin.com/company/helloar",
      name: "linkedin",
      icon: "../../../../icons/linkedin-outline.svg",
    },
  ];
  let platform = [
    {
      title: "AR VR Solutions",
      url: "/arvr",
    },
    {
      title: "3D Product Catalog",
      url: "/interactive3D",
    },
    {
      title: "Product Interaction & Configuration",
      url: "/product-configurators",
    },
    {
      title: "Background Removal",
      url: "/background-removal",
    },
    {
      title: "Metaverse",
      url: "/metaverse",
    },
  ];
  let industries = [
    {
      title: "Automotive",
      url: "/automotive",
    },
    {
      title: "Home Furnishing",
      url: "/home-furnishing",
    },

    {
      title: "Furniture",
      url: "/furniture",
    },
    {
      title: "Sanitary Ware",
      url: "/sanitary-ware",
    },
  ];
  let learn = [
    {
      title: "About Us",
      url: "/about-us",
    },
    {
      title: "Gallery",
      url: "/gallery",
    },
    {
      title: "Technology",
      url: "/technology",
    },
  ];
  let links = [
    {
      title: "Terms & Conditions",
      url: "#",
    },
    {
      title: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      title: "Sitemap",
      url: "/sitemap-0.xml",
    },
    {
      title: "Disclaimer",
      url: "#",
    },
  ];

  return (
    <div className="footer">
      <div className="spacer"></div>
      <Container>
        <Row>
          <Col xs={12} md={3} className="infoSocials">
            <Link to="/">
              <img
                src="../../../../images/logo-dark.svg"
                className="logo"
                alt="logo"
              />
            </Link>
            <div className="content">
              <p>
                Delivering the future of online shopping experiences. Increase
                conversions, engagement and leads with our interactive and
                immersive shopping experience.
              </p>
              <div className="d-flex">
                {socials.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div className="box d-flex align-items-center justify-content-center">
                      <img src={item.icon} alt={item.name} />
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={6} md={{ span: 2, offset: 1 }} className="linkList">
            <h5>Platform</h5>
            <ul className="p-0">
              {platform.map((item, index) => (
                <li key={index}>
                  <Link key={index} to={item.url}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={{ span: 3, offset: 1 }} className="linkList">
            <h5>Industries</h5>
            <ul className="p-0">
              {industries.map((item, index) => (
                <li key={index}>
                  <Link key={index} to={item.url}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={2} className="linkList">
            <h5>Learn</h5>
            <ul className="p-0">
              {learn.map((item, index) => (
                <li key={index}>
                  <Link key={index} to={item.url}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="spacer-sm"></div>
      <Container fluid className="copyright">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <p className="my-3">© {today} HelloAR. All rights reserved</p>
            </Col>
            <Col md={5}>
              <ul className="w-100 d-flex align-items-center p-0 m-0">
                {links.map((item, index) => (
                  <li key={index}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Footer;

import * as React from "react";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="7hvelykxl4qs3wzdfnvtuclff8cp9t"
        />
        <script src="../../zoho/zoho.js" type="text/javascript" async />

        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        ></script>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
      </Helmet>
      <html lang="en" />
      <title>HelloAR</title>
      <meta httpEquiv="Cache-control" content="public" />
      <meta name="robots" content="all" />
    </>
  );
};

export default Header;

import React from "react";
import Layout from "../components/Layout";
import Portfolio from "../containers/Portfolio/Portfolio";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonBanner from "../components/ButtonBanner";
import SuccessAnalytics from "../components/SuccessAnalytics/SuccessAnalytics";
import CaseStudy from "../sections/homepage/CaseStudy";

const caseStudies = () => {
  const caseStudies = [
    {
      image: "../../images/casestudy/cars24.webp",
      title: "Stunning 360 car visuals in minutes",
      footer: "",

      link: "/cars24",
    },
    {
      image: "../../images/casestudy/featherlite.webp",
      title:
        "Immersive experience for work from home furniture in just six weeks",
      footer: "",
      link: "/featherlite",
    },
    {
      image: "../../images/casestudy/ashirvad.webp",
      title: "Empower your sanitaryware sales",
      footer: "",
      link: "/ashirvad",
    },
    {
      image: "../../images/casestudy/artevenue.webp",
      title: "Bringing art to life in a week",
      footer: "",
      link: "/artevenue",
    },
    {
      image: "../../images/casestudy/terrainart.webp",
      title: "Delighting art lovers with AR solutions",
      footer: "",
      link: "/terrainart",
    },
    {
      image: "../../images/casestudy/atypicaladvantage.webp",
      title: "Enabling atypical with powerful AR solution",
      footer: "",
      link: "/atypical-advantage",
    },
    {
      image: "../../images/casestudy/onlymat.webp",
      title: "Reimagining doormats industry",
      footer: "",
      link: "/onlymat",
    },
    {
      image: "../../images/casestudy/rugberry.webp",
      title: "Redefining online sales for rugs & carpets",
      footer: "",
      link: "/rugberry",
    },
    {
      image: "../../images/casestudy/gigacars.webp",
      title: "Studio grade imaging solution for Giga Cars",
      footer: "",
      link: "/giga-cars",
    },
    {
      image: "../../images/casestudy/spinny.webp",
      title: "Studio grade images & 360 car visuals",
      footer: "",
      link: "/spinny",
    },
    {
      image: "../../images/casestudy/wakefit.webp",
      title: "Feel the comfort virtually",
      footer: "",
      link: "/wakefit",
    },
    {
      image: "../../images/casestudy/guarented.webp",
      title: "A fun way of buying furniture",
      footer: "",
      link: "/guarented",
    },
    {
      image: "../../images/casestudy/wonderchef.webp",
      title: "The smartest way to show off your kitchen appliances",
      footer: "",
      link: "/wonderchef",
    },
    {
      image: "../../images/casestudy/codesustain.webp",
      title: "360 experience for ecommerce platform",
      footer: "",
      link: "/codesustain",
    },
    {
      image: "../../images/casestudy/craftmaestro.webp",
      title: "Experiential buying for handicrafts",
      footer: "",
      link: "/craft-maestro",
    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>
          HelloAR: Augmented Reality Examples | Virtual Reality Examples
        </title>
        <meta
          name="description"
          content="Check out the augmented reality examples & virtual reality examples in real life.  Here's how companies are multiplying revenues with AR VR solutions"
        />
        <meta
          name="keywords"
          content="augmented reality examples, virtual reality examples"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>
      <div className="container-fluid catalog">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="container">
          <h1 className="mb-5 text-center h1">
            We’re changing online sales and purchases
          </h1>
          <SuccessAnalytics />
          <h1 className="mt-5 text-center h2">Explore our work </h1>
          <div className="container">
            <div className="w-100 d-inline-flex flex-column align-items-center">
              {/* <CaseStudy caseStudies={caseStudies} hideHeader /> */}
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
      </div>
    </Layout>
  );
};

export default caseStudies;

import { Link } from "gatsby";
import React from "react";
import "./ButtonBanner.scss";
import { PopupButton } from "react-calendly";

interface IProps {
  buttonTitle: string;
  buttonUrl: string;
}

const ButtonBanner: React.FC<IProps> = ({
  buttonTitle,
  buttonUrl,
  children,
}) => {
  return (
    <div className="bannerContainer px-0 px-md-5">
      <div className="spacer-sm"></div>
      {children}
      <div className="spacer-sm"></div>
      <div className="text-center">
        <div className="d-flex justify-content-center justify-content-lg-start">
          {/* {buttonUrl === "/contact-us" ? (
            <PopupButton
              url="https://calendly.com/helloar/30min?primary_color=57ca85"
              styles={{
                padding: "0.75rem 4rem",
                border: "none",
                borderRadius: "50px",
                fontFamily: "inherit",
                fontWeight: 700,
                background: "white",
                color: "#57ca85",
              }}
              className="button m-auto font-weight-bold"
              rootElement={document.getElementById("___gatsby")}
              text="REQUEST A DEMO"
            />
          ) : ( */}
          <Link className="m-auto" to={buttonUrl}>
            <div className="button">
              <p className="mb-0 font-weight-bold">{buttonTitle}</p>
            </div>
          </Link>
          {/* )} */}
        </div>
      </div>
      <div className="spacer-sm"></div>
    </div>
  );
};

export default ButtonBanner;

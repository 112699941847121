import { Link } from "gatsby";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { FaBars, FaTimes } from "react-icons/fa";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
// import { PopupButton } from "react-calendly";

import "../../styles/menu.scss";

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [accordionOpen, setAccordionOpen] = useState<string>("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleAccordion = (name) => {
    if (accordionOpen === name) {
      setAccordionOpen("");
    } else {
      setAccordionOpen(name);
    }
  };

  return (
    <div className="position-fixed w-100 navz-index">
      <div className="container-fluid menu desktop">
        <div>
          <div className="d-flex">
            <div className="row w-100 d-flex align-items-center">
              <div className="col-1 pr-3">
                <Link to="/">
                  <img
                    src="../../../../images/logo-dark.svg"
                    width={125}
                    height={25}
                    className="logo"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col d-flex align-items-center justify-content-between ml-lg-3 ml-xl-3">
                <ul className="d-inline-flex col justify-content-center justify-content-lg-start mb-0 ml-auto">
                  <li className="bt-2 subnav">
                    <a href="#">Platform</a>
                    <div className="subnav-content">
                      <Link to="/arvr">AR/VR Solutions</Link>
                      <Link to="/interactive3D">
                        Interactive 3D Product Catalog
                      </Link>
                      <Link to="/product-configurators">
                        Product Configurator
                      </Link>
                      <Link to="/background-removal">Background Removal</Link>
                      <Link to="/metaverse">Metaverse</Link>
                    </div>
                  </li>
                  <li className="bt-2 subnav">
                    <a href="#">Industries</a>
                    <div className="subnav-content">
                      <Link to="/automotive">Automotive</Link>
                      <Link to="/home-furnishing">Home Furnishing</Link>
                      <Link to="/furniture">Furniture</Link>
                      <Link to="/sanitary-ware">Sanitaryware</Link>
                    </div>
                  </li>
                  {/* <li className="bt-2">
                    <Link to="/case-studies">Case Studies</Link>
                  </li> */}
                  <li className="bt-2">
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li className="bt-2 subnav">
                    <a href="#">Learn</a>
                    <div className="subnav-content">
                      <Link to="/about-us">About Us</Link>
                      {/* <Link to="/blog">Blogs</Link> */}
                      <Link to="/technology">Technology</Link>
                      {/* <Link to="/learn-helloar-app">
                        How to Use HelloAR app
                      </Link> */}
                    </div>
                  </li>
                  {/* <li className="bt-2 subnav">
                    <a href="/experience-zone/index.html" target="_blank">
                      Experience Zone
                    </a>
                  </li> */}
                </ul>
                <ul className="col-4 pr-0 d-flex justify-content-end mt-3">
                  <li className="login">
                    <a href="/contact-us" rel="noreferrer" target="_blank">
                      REQUEST A DEMO
                    </a>
                    {/* <PopupButton
                      url="https://calendly.com/helloar/30min?primary_color=57ca85"
                      styles={{
                        padding: "0.5rem 1rem",
                        border: "none",
                        borderRadius: "50px",
                        fontFamily: "inherit",
                        fontWeight: 700,
                        background: "#57ca85",
                        color: "white",
                      }}
                      rootElement={document.getElementById("__gatsby")}
                      text="REQUEST A DEMO"
                    /> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid menu mobile ${
          !menuOpen ? "closed" : "opened"
        }`}
      >
        <div className="pr-sm-4 navbar d-block p-0">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img
                src="../../../../images/logo-dark.svg"
                width={90}
                height={19}
                className="logo"
                alt="logo"
              />
            </Link>
            <div className="d-flex justify-content-between align-items-center">
              <div className="login pr-3">
                <a href="/contact-us" rel="noreferrer" target="_blank">
                  REQUEST A DEMO
                </a>
                {/* <div className="login mr-3 align-items-center">
                <PopupButton
                  url="https://calendly.com/helloar/30min?primary_color=57ca85"
                  rootElement={document.getElementById("___gatsby")}
                  text="REQUEST A DEMO"
                  styles={{
                    padding: "0.5rem 1rem",
                    border: "none",
                    borderRadius: "50px",
                    fontFamily: "inherit",
                    fontWeight: 700,
                    background: "#57ca85",
                    color: "white",
                  }}
                /> */}
              </div>
              <div
                className="d-flex justify-content-center text-dark"
                onClick={toggleMenu}
              >
                {menuOpen ? <FaTimes /> : <FaBars />}
              </div>
            </div>
          </div>
          <div className="content d-flex flex-column">
            <ul className="p-0 mb-0">
              {/* <li className="login">
                <a
                  href="https://app.helloar.io"
                  rel="noreferrer"
                  target="_blank"
                >
                  Request a demo
                </a>
              </li> */}
              <li className="bt-2" onClick={(e) => handleAccordion("services")}>
                <a
                  data-toggle="collapse"
                  href="#services"
                  role="button"
                  className="d-flex justify-content-between"
                >
                  Platform
                  {accordionOpen === "services" ? (
                    <BiChevronUp />
                  ) : (
                    <BiChevronDown />
                  )}
                </a>
                <ul
                  className={`collapse ${
                    accordionOpen !== "services" && "d-none"
                  }`}
                  id="services"
                >
                  <li>
                    <Link to="/arvr">AR/VR Solutions</Link>
                  </li>
                  <li>
                    <Link to="/interactive3D">
                      Interactive 3D Product Catalog
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-configurators">
                      Product Configurator
                    </Link>
                  </li>
                  <li>
                    <Link to="/background-removal">Background Removal</Link>
                  </li>
                  <li>
                    <Link to="/metaverse">Metaverse</Link>
                  </li>
                </ul>
              </li>
              <li
                className="bt-2"
                onClick={(e) => handleAccordion("industries")}
              >
                <a
                  data-toggle="collapse"
                  href="#industries"
                  role="button"
                  className="d-flex justify-content-between"
                >
                  Industries
                  {accordionOpen === "industries" ? (
                    <BiChevronUp />
                  ) : (
                    <BiChevronDown />
                  )}
                </a>
                <ul
                  className={`collapse ${
                    accordionOpen !== "industries" && "d-none"
                  }`}
                  id="industries"
                >
                  <li>
                    <Link to="/automotive">Automotive</Link>
                  </li>
                  <li>
                    <Link to="/home-furnishing">Home Furnishing</Link>
                  </li>
                  <li>
                    <Link to="/furniture">Furniture</Link>
                  </li>
                  <li>
                    <Link to="/sanitary-ware">Sanitaryware</Link>
                  </li>
                </ul>
              </li>
              <li className="bt-2" onClick={(e) => handleAccordion("learn")}>
                <a
                  data-toggle="collapse"
                  href="#learn"
                  role="button"
                  className="d-flex justify-content-between"
                >
                  Learn
                  {accordionOpen === "learn" ? (
                    <BiChevronUp />
                  ) : (
                    <BiChevronDown />
                  )}
                </a>
                <ul
                  className={`collapse ${
                    accordionOpen !== "learn" && "d-none"
                  }`}
                  id="learn"
                >
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/blog">Blogs</Link>
                  </li> */}
                  <li>
                    <Link to="/technology">Technology</Link>
                  </li>
                  {/* <li>
                    <Link to="/learn-helloar-app">How to use HelloAR app</Link>
                  </li> */}
                </ul>
              </li>
              {/* <li className="bt-2 subnav">
                <a href="/experience-zone/index.html" target="_blank">
                  Experience Zone
                </a>
              </li> */}
              {/* <li className="bt-2">
                <Link to="/case-studies">Case Studies</Link>
              </li> */}
              <li className="bt-2">
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;

import React from "react";
import { Row, Col } from "react-bootstrap";
import * as styles from "./SuccessAnalytics.module.css";

const SuccessAnalytics: React.FC = () => {
  return (
    <div className="px-4 px-md-0">
      <Row className="mb-3">
        <Col md={{ span: 6, offset: 3 }}>
          <p className={styles.subtext}>
            We have worked with the top brands across categories and have
            empowered thousands of customers and sales specialists.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 2, offset: 3 }}>
          <div className={styles.cardContainer}>Millions of views</div>
        </Col>
        <Col md={2}>
          <div className={styles.cardContainer}>
            30,000+ hours of user interaction
          </div>
        </Col>
        <Col md={2}>
          <div className={styles.cardContainer}>
            3000+ hours of AR engagement
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SuccessAnalytics;

import React from "react";
import Footer from "./common/Footer";
import Header from "./common/Header";
import Menu from "./common/Menu";
import Modals from "./Modals";
import { PopupButton } from "react-calendly";
import "../styles/layout.scss";
import "../styles/xxl.scss";

const Layout = (props: any) => {
  return (
    <main className="position-relative" id="root">
      <Header />
      <Menu />

      {props.children}
      {/* <Modals /> */}
      {/* <a
        href="https://wa.me/919740511346?text=Hey%20HelloAR."
        rel="noreferrer"
        target="_blank"
      >
        <div className="whatsapp">
          <img src="../../icons/whatsapp.svg" alt="whatsapp" />
        </div>
      </a> */}
      <script
        type="text/javascript"
        id="zsiqchat"
        dangerouslySetInnerHTML={{
          __html: `
        var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "f7499b9ace710006858c3e08f964476abee12bc0b1df7d13a3baf4d97bf2123f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
        `,
        }}
      />
      <Footer />
    </main>
  );
};

export default Layout;
